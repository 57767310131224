import React from "react";
import {
	Box,
	Grid,
	Button,
	Divider,
	Container,
	Typography
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import {
	Link
} from "react-router-dom";
import {
	HeaderMenuElement
} from "./../../../../components/headers/HeaderMini";
import allTranslations from "../../../../locales/allTranslations";

const HeaderMini = (props) => {
	const {
		label,
		caption,
		buttonLabel,
		onClickRight,
		buttonDisabled,
	} = props;
	const classes = useStyles();
	return (
		<div className="header">
			<Container maxWidth="xl">
				<Grid container alignItems="center">
					<Grid item xs={4}>
						<div className="header-content">
							<HeaderMenuElement/>
							<Link to="/" className="header-logo">
								<img src={require('../../../../assets/image/svg/logos/header-logo.svg').default}/>
							</Link>
						</div>
					</Grid>
					<Grid item xs={4}>
						<Grid container spacing={1}>
							<Grid item>
								<Typography className={classes.orderNumber}>
									{allTranslations('Заказ')} {label}
								</Typography>
							</Grid>
							<Grid item>
								<Divider orientation="vertical" style={{ borderColor: "#828698", opacity: 0.2 }}/>
							</Grid>
							<Grid item>
								<Typography
									className={classes.caption}
									dangerouslySetInnerHTML={{
										__html: caption
									}}
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={4}>
						<div className="header-content">
							<Box ml="auto"/>
							{Boolean(buttonLabel) && (
								<Button
									size="small"
									variant="contained"
									onClick={onClickRight}
									disabled={buttonDisabled}
								>{ buttonLabel }</Button>
							)}
						</div>
					</Grid>
				</Grid>
			</Container>
		</div>
	)
};

const useStyles = makeStyles(() => ({
	headerMenu: {},
	headerMenuButton: {
		width: 48,
		height: 48,
		borderRadius: "100%",
		backgroundColor: "white!important",
	},
	orderNumber: {
		fontWeight: "500",
		fontSize: "26px",
		lineHeight: "26px",
		letterSpacing: "-0.03em",
		color: "#210323",
	},
	caption: {
		fontWeight: "500",
		fontSize: "13px",
		lineHeight: "13px",
		letterSpacing: "-0.03em",
		color: "#828698",
	}
}));

export default HeaderMini
