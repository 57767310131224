import agent from "../agent/agent";

const snapshotVideo = async (video, {width, height, isTurn}) => {
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d');
  if (isTurn) {
    ctx.translate(canvas.width/2,canvas.height/2);
    ctx.rotate(180 * Math.PI/180)
    ctx.translate(-canvas.width / 2, -canvas.height / 2)
  }
  ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

  let blog = await new Promise(resolve => canvas.toBlob(resolve));
  blog = await new File([blog], `blog.jpg`, {type:'image/jpg'});

  return blog;
};
const splitImage = async (blob, {width, height}) => {
  const image = new Image();
  image.src = URL.createObjectURL(blob);
  await image.decode();

  const canvasLeft = document.createElement('canvas');
  canvasLeft.width = width / 2;
  canvasLeft.height = height;
  const contextLeft = canvasLeft.getContext('2d');
  contextLeft.drawImage(image, 0, 0, width, height);
  let blobLeft = await new Promise(resolve => canvasLeft.toBlob(resolve));
  blobLeft = await new File([blobLeft], `img_000_0.jpg`, {type:'image/jpg'});
  const imageLeft = new Image();
  imageLeft.id = "left";
  imageLeft.src = URL.createObjectURL(blobLeft);

  const canvasRight = document.createElement('canvas');
  canvasRight.width = width / 2;
  canvasRight.height = height;
  let contextRight = canvasRight.getContext('2d');
  contextRight.drawImage(image, width / 2, 0, width / 2, height, 0, 0, width / 2, height);
  let blobRight = await new Promise(resolve => canvasRight.toBlob(resolve,'image/jpg', 1));
  blobRight = await new File([blobRight], `img_000_1.jpg`, {type:'image/jpg'});

  return [
    blobLeft,
    blobRight
  ]
};
const cropImageByArea = async (blob, {width, height, x, y}) => {
  const image = new Image();
  image.src = URL.createObjectURL(blob);
  await image.decode();

  const canvasOriginal = document.createElement('canvas');
  const contextOriginal = canvasOriginal.getContext('2d');
  contextOriginal.drawImage(image, 0, 0, width, height);
  const blobOriginal = await new Promise(resolve => canvasOriginal.toBlob(resolve));
  const imageOriginal = new Image();
  imageOriginal.src = URL.createObjectURL(blobOriginal);

  const canvasRight = document.createElement('canvas');
  canvasRight.width = width;
  canvasRight.height = height;
  let contextRight = canvasRight.getContext('2d');
  contextRight.drawImage(image, x, y, width, height, 0, 0, width, height);
  let blobRight = await new Promise(resolve => canvasRight.toBlob(resolve,'image/jpg', 1));
  blobRight = await new File([blobRight], `blobRight.jpg`, {type:'image/jpg'});

  return blobRight
};
const getOriginalSizeImage = async (blob) => {
  const imageFile = new Image();
  imageFile.src = URL.createObjectURL(blob);
  await imageFile.decode();
  return {
    width: imageFile.naturalWidth,
    height: imageFile.naturalHeight
  }
}
const getImageBlobFromOrder = async (imageInfo) => {
  const actionUrl = [`/calculation/${ imageInfo?.calculationId }/${ imageInfo.id }`].join("");
  const resBuffer = await agent.get(actionUrl).then((res) => {
    return res.data?.data
  });
  const buffer = new Uint8Array(resBuffer).buffer;
  return new Blob([buffer])
}

const getBlobFromUrl = (myImageUrl) => {
  return new Promise((resolve, reject) => {
    let request = new XMLHttpRequest();
    request.open('GET', myImageUrl, true);
    request.responseType = 'blob';
    request.onload = () => {
      resolve(request.response);
    };
    request.onerror = reject;
    request.send();
  })
}
const getBlobFromBase64 = async (path) => {
  const srcPath = `data:image/png;base64,${ path }`;
  return await getBlobFromUrl(srcPath);
};

export {
  splitImage,
  snapshotVideo,
  cropImageByArea,
  getOriginalSizeImage,
  getImageBlobFromOrder,

  getBlobFromUrl,
  getBlobFromBase64
}
