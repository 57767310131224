import React, {Component} from "react";
import {
	Box,
	Grid,
	Select,
	Button,
	MenuItem,
	Backdrop,
	InputLabel,
	Typography,
	FormControl,
	CircularProgress
} from "@mui/material";
import {
	withStyles,
	makeStyles
} from "@mui/styles";
import {
	SwitchCustom,
	DialogConfirmation
} from "../../components";
import {
	Notification,
	notificationTypes
} from "../../common/Notification";
import agent from "../../agent/agent";
import allTranslations from "../../locales/allTranslations";
import PopupState, {bindPopover, bindTrigger} from "material-ui-popup-state";
import {GTranslate as GTranslateIcon} from "@mui/icons-material";

class Settings extends Component {
	constructor(props) {
		super(props);

		this.state = {
			settings: {...this.props.settings},
			isBackdrop: false
		};
		this.refDialogConfirmation = React.createRef();
	}

	changeSettings = async ({ target }) => {
		const { name, value } = target;
		let settings = {...this.state.settings};
		settings[name] = value;
		this.setState({ settings });
	};
	submitSettings = async () => {
		await this.setState({ isBackdrop: true });
		const cameraId = this.props?.user?.equipment?.id;
		const body = {
			...this.props?.user?.equipment,
			isRotate: this.state.settings?.isTurnCamera
		};
		['createdAt', 'deletedAt', 'id', 'lastCalibrationAt', 'updatedAt'].map((key) => {
			delete body[key]
		})
		const res = await agent.patch(`/equipments/${ cameraId }`, body).then((res) => {
			return res.data
		}).catch((err) => {
			return {error: allTranslations('Ошибка сервера')}
		});
		if (res?.error) {
			await this.setState({ isBackdrop: false });
			Notification({
				message: res?.error,
				type: notificationTypes.error
			});
			return
		}

		const userMe = await agent.get('/auth/me').then((res) => {
			if (res.data?.code === 401) {
				return null
			}
			return res.data
		}).catch(() => null);
		await this.props.setAuthMe(userMe);
		await this.setState({
			isBackdrop: false,
			settings: {...this.props.settings}
		});
		Notification({
			message: allTranslations('Настройки успешно установлены'),
			type: notificationTypes.success
		});
	};

	logout = async (isConfirm) => {
		if (!isConfirm) {
			this.refDialogConfirmation.current.open({
				message: allTranslations('Вы действительно хотите выйти из системы?'),
				onSubmit: this.logout.bind(null, true),
				acceptButtonTitle: allTranslations('Да, выйти')
			});
			return
		}
		localStorage.removeItem('token')
		await this.props.setAuthMe(null);
	}

	render() {
		const {
			classes
		} = this.props;
		const {
			settings,
			isBackdrop
		} = this.state;

		return (
			<>
        <Box className={classes.section}>
	        <Typography className={classes.title}>{allTranslations('Настройки')}</Typography>
	        <Grid container spacing={4}>
		        <Grid item xs={12}>
			        <Grid container alignItems="center" justifyContent="space-between">
				        <Grid item>
					        <Typography className={classes.formLabel}>{allTranslations('Инвертировать изображение?')}</Typography>
				        </Grid>
				        <Grid item>
					        <SwitchCustom
						        value={settings.isTurnCamera}
						        name="isTurnCamera"
						        onChange={(value) => this.changeSettings({target: {value, name: 'isTurnCamera'}})}
					        />
				        </Grid>
			        </Grid>
		        </Grid>
		        <Grid item xs={12}>
			        <LocaleMenu/>
		        </Grid>
	        </Grid>
        </Box>
				<Box className={classes.sectionAction}>
					<Button
						fullWidth
						size="large"
						variant="contained"
						onClick={this.submitSettings}
					>{allTranslations('Сохранить')}</Button>
					<Button
						fullWidth
						size="small"
						variant="outlined"
						color="secondary"
						sx={{mt: 1}}
						onClick={this.logout.bind(null, false)}
					>{allTranslations('Выход из системы')}</Button>
				</Box>

				<Backdrop open={isBackdrop}>
					<CircularProgress/>
				</Backdrop>
				<DialogConfirmation ref={this.refDialogConfirmation}/>
			</>
		);
	}
}
const LocaleMenu = React.memo(() => {
	const classes = useStyles();

	const languages = [
		{label: "Русский", value: "ru", icon: require('../../assets/image/svg/countries/rus-flug.svg').default},
		{label: "English", value: "en", icon: require('../../assets/image/svg/countries/eng-flug.svg').default},
	];

	const [language, setLanguage] = React.useState(() => {
		return localStorage.getItem("locale") || 'ru'
	});

	const handleChangeLanguages = (event, val) => {
		const _value = val?.props?.value;
		localStorage.setItem("locale", _value);
		window.location.reload();
	}

	return (
		<Box className={classes.localeMenu}>
			<div className={classes.localeMenuLabel}>{allTranslations('Язык')}</div>
			<FormControl fullWidth size="small">
				<Select
					size="small"
					value={language}
					onChange={handleChangeLanguages}
				>
					{languages.map((_item, index) => (
						<MenuItem key={`asdasdasd-${index}`} value={_item.value} className={classes.localeMenuMenuItem}>
							<img src={_item.icon} className={classes.localeMenuMenuItemIcon}/>
							{_item.label}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</Box>
	)
});

const styles = {
	root: {},
	title: {
		marginBottom: 24,
		fontSize: 28,
		lineHeight: 1,
		color: "#210323",
		fontWeight: "500",
		letterSpacing: "-0.03em",
	},
	section: {
		width: "100%",
		maxWidth: "640px",
		margin: "0 auto",
		background: "white",
		boxShadow: "0px 4px 20px 0px #2103230A",
		borderRadius: 24,
		border: "1px solid #E6E7EA",
		padding: "30px 36px",
		boxSizing: "border-box"
  },
	sectionAction: {
		marginTop: 8,
		width: "100%",
		maxWidth: "640px",
		margin: "0 auto",
		"& .MuiButtonBase-root": {
			borderRadius: 24
		}
	},
	formLabel: {
		fontSize: 18,
		lineHeight: 1,
		color: "#210323",
		// fontWeight: "500",
		letterSpacing: "-0.03em",
	}
};
Settings = withStyles(styles)(Settings);

const useStyles = makeStyles(() => ({
	localeMenu: {
		"& .MuiSelect-select": {
			display: "flex",
			alignItems: "center",
		}
	},
	localeMenuLabel: {
		marginBottom: 8,

		fontSize: "20px",
		color: "#828698",
		fontWeight: "400",
		lineHeight: "24px",
		letterSpacing: "-0.03em",
	},
	localeMenuMenuItem: {
		display: "flex",
		alignItems: "center",
		fontSize: "16px",
		color: "#210323",
		fontWeight: "500",
		padding: "6px 22px"
	},
	localeMenuMenuItemIcon: {
		width: 20,
		height: 20,
		borderRadius: "100%",
		objectFit: "cover",
		border: "1px solid #E6E7EA",
		marginRight: 8,
	}
}));

export default Settings
