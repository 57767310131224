import React from "react";
import {
	Box,
	Grid,
	Button,
	Popover,

	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	ListItemButton,
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import {
	Print as PrintIcon,
	UploadFile as UploadFileIcon
} from "@mui/icons-material";
import PopupState, {
	bindTrigger,
	bindPopover,
} from "material-ui-popup-state";
import allTranslations from "../../../../locales/allTranslations";

const BottomControls = (props) => {
	const {
		isPrint,
		onPrint,

		isOneS,
		onSend1C
	} = props;
	const classes = useStyles();

	const eventClick = (popupState, event) => {
		popupState.close();
		event();
	}

	return (
		<PopupState
			variant="popover"
			popupId="demo-popup-popover"
		>
			{(popupState) => (
				<>
					<Button
						size="large"
						variant="contained"
						{...bindTrigger(popupState)}
					>
						{allTranslations('Отправить результат')}
					</Button>
					<Popover
						{...bindPopover(popupState)}
						classes={{ root: classes.popover }}
						anchorOrigin={{vertical: 'top', horizontal: 'left'}}
						transformOrigin={{vertical: 'bottom', horizontal: 'left'}}
					>
						<List>
							<ListItem>
								<ListItemButton
									disabled={Boolean(!isPrint)}
									onClick={eventClick.bind(this, popupState, onPrint)}
								>
									<ListItemIcon>
										<PrintIcon color="primary"/>
									</ListItemIcon>
									<ListItemText primary="Печать"/>
								</ListItemButton>
							</ListItem>
							<ListItem>
								<ListItemButton
									disabled={Boolean(!isOneS)}
									onClick={eventClick.bind(this, popupState, onSend1C)}
								>
									<ListItemIcon>
										<UploadFileIcon color="primary"/>
									</ListItemIcon>
									<ListItemText primary="В 1С"/>
								</ListItemButton>
							</ListItem>
						</List>
					</Popover>
				</>
			)}
		</PopupState>
	)
}

const useStyles = makeStyles(() => ({
	popover: {
		"& .MuiPaper-root": {
			padding: "12px",
			boxShadow: "0px 4px 20px 0px #2103230A",
			border: "1px solid #E6E7EA",
			transform: "translateY(-12px)",
			borderRadius: 12,
		},
		"& .MuiList-root": {
			padding: 0,
		},
		"& .MuiListItem-root": {
			padding: 0,
		},
		"& .MuiButtonBase-root": {
			padding: "10px 16px",
			borderRadius: "10px",
		},
		"& .MuiListItemIcon-root": {
			width: 32,
			height: 32,
			minWidth: 0,
			marginRight: "16px",
		},
		"& .MuiListItemText-root .MuiTypography-root": {
			fontWeight: "500",
			fontSize: "22px",
			lineHeight: "110%",
			letterSpacing: "-0.03em",
			color: "#210323",
		},
	}
}));

export default BottomControls
