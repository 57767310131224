import React from "react";
import {
	Box,
	Button,
	Typography
} from "@mui/material";
import {
	withStyles,
	makeStyles,
} from "@mui/styles";
import {
	ChevronLeft as ChevronLeftIcon,
	ChevronRight as ChevronRightIcon,
} from "@mui/icons-material";
import {
	ContrastSlider,
	VisibleContent,
} from "../../../../components";
import {
	TransformWrapper,
	TransformComponent
} from "react-zoom-pan-pinch";
import {Rnd} from "react-rnd";
import {palette} from "../../../../theme/common";
import clsx from "clsx";
import ButtonPrevNext from "../common/ButtonPrevNext";
import ResetChanged from "../common/ResetChanged";
import allTranslations from "../../../../locales/allTranslations";

class OutlineGlasses extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			editElement: null
		};
	}

	openEditElement = ({ rects, blob, direction, rect, caption, onSubmit }) => {
		this.setState({
			editElement: {
				blob,
				rects,
				caption,
				onSubmit,
				direction,
				rectBaseName: rect
			}
		});
	};
	closeEditElement = () => {
		this.setState({
			editElement: null
		})
	};
	changeEditElement = (direction, rects) => {
		const { editElement } = this.state;
		if (direction === 'left') {
			this.props.onChangeRects0(rects);
		}
		if (direction === 'right') {
			this.props.onChangeRects1(rects);
		}
		editElement.onSubmit();
	};

	nextEditForm = async () => {
		const { blobRight, rects1 } = this.props;
		this.openEditElement({
			rects: rects1,
			blob: blobRight,
			rect: "rect_1",
			caption: allTranslations('П'),
			direction: "right",
			onSubmit: this.closeEditElement.bind(null)
		})
	};

	render () {
		const {
			classes,

			blobLeft,
			blobRight,

			rects0,
			rects1,
		} = this.props;
		const {
			editElement
		} = this.state;

		if (Boolean(editElement)) {
			return (
				<ElementRectsEditForm
					key={`ElementRectsEditForm-${ editElement.direction }`}
					item={editElement}
					onClose={this.closeEditElement}
					onSubmit={this.changeEditElement}
				/>
			)
		}
		return (
			<Box className={classes.root}>
				<Box className={classes.calcContent}>
					<ElementRectsSmall
						blob={blobLeft}
						rects={rects0}
						rectBaseName="rect_0"
						caption="Л"
						onOpen={this.openEditElement.bind(this, {
							rects: rects0,
							blob: blobLeft,
							rect: "rect_0",
							caption: allTranslations('Л'),
							direction: "left",
							onSubmit: this.nextEditForm.bind(null)
						})}
					/>
					<ElementRectsSmall
						blob={blobRight}
						rects={rects1}
						rectBaseName="rect_1"
						caption={allTranslations('П')}
						onOpen={this.openEditElement.bind(this, {
							rects: rects1,
							blob: blobRight,
							rect: "rect_1",
							caption: allTranslations('П'),
							direction: "right",
							onSubmit: this.closeEditElement.bind(null)
						})}
					/>
				</Box>
				<Box className={classes.infoContent}>
					<div className={classes.title} dangerouslySetInnerHTML={{__html: allTranslations('Уточните<br/>область окуляров')}}/>
					<div className={classes.message} dangerouslySetInnerHTML={{__html: allTranslations('На изображении укажите прямоугольные области Растягивая за границы и углы прямоугольников Для сохранения и перехода к следующему этапу разметки нажмите вверху на “Сохранить”')}}/>
				</Box>
			</Box>
		)
	}
};
const ElementRectsSmall = (props) => {
	const {
		blob,
		rects,
		caption,
		rectBaseName,
		onOpen
	} = props;
	const classes = useStylesElementRectsSmall();

	const refImage = React.createRef();

	const [imageUrl, setImageUrl] = React.useState(() => {
		return URL.createObjectURL(blob)
	});

	const [scaleSpan, setScaleSpan] = React.useState(0);
	const [scaleAction, setScaleAction] = React.useState(0);
	const initState = async () => {
		if (!refImage.current) {
			setTimeout(async () => {
				await initState();
			}, 100);
			return
		}
		const { width: imageWidth } = refImage.current.getBoundingClientRect();
		if (imageWidth <= 0) {
			setTimeout(async () => {
				await initState();
			}, 100);
			return
		}

		setScaleSpan(1520 / imageWidth);
		setScaleAction(imageWidth / 1520);
	};
	React.useState(() => {
		(async () => {
			await initState();
		})();
	}, [imageUrl, rects])

	const handleOpen = () => {
		if (Boolean(!rects)) {
			return
		}
		onOpen();
	};

	return (
		<Box
			className={clsx({
				[classes.root]: true,
				'--loading': Boolean(!rects)
			})}
			onClick={handleOpen}
		>
			<img
				ref={refImage}
				src={imageUrl}
				className={classes.image}
			/>
			<Box
				className={classes.action}
				sx={{ transform: `scale(${ scaleAction })` }}
			>
				<VisibleContent visible={Boolean(rects?.[`${rectBaseName}l`] && rects?.[`${rectBaseName}r`])}>
					<Rnd
						bounds="parent"
						className={clsx({
							[classes.actionRnd]: true,
							'rnd-triangles': true,
							'--changed': Boolean(rects?.[`${rectBaseName}l`]?.changed)
						})}
						scale={scaleAction}
						position={{x: rects?.[`${rectBaseName}l`]?.x, y: rects?.[`${rectBaseName}l`]?.y}}
						size={{width: rects?.[`${rectBaseName}l`]?.width, height: rects?.[`${rectBaseName}l`]?.height}}
					><span style={{transform: `scale(${scaleSpan})`}}>{allTranslations('Левый')}</span></Rnd>
					<Rnd
						bounds="parent"
						className={clsx({
							[classes.actionRnd]: true,
							'rnd-triangles': true,
							'--changed': Boolean(rects?.[`${rectBaseName}r`]?.changed)
						})}
						scale={scaleAction}
						position={{x: rects?.[`${rectBaseName}r`]?.x, y: rects?.[`${rectBaseName}r`]?.y}}
						size={{width: rects?.[`${rectBaseName}r`]?.width, height: rects?.[`${rectBaseName}r`]?.height}}
					><span style={{transform: `scale(${scaleSpan})`}}>{allTranslations('Правый')}</span></Rnd>
				</VisibleContent>
			</Box>
			<div className={classes.caption}>{caption}</div>
		</Box>
	)
};
const ElementRectsEditForm = (props) => {
	const {
		item,
		isOpen,
		onClose,
		onSubmit
	} = props;
	const classes = useStylesDialogEditContour();

	const refImage = React.createRef();
	const [imagePath, setImagePath] = React.useState('');
	const [scaleSpan, setScaleSpan] = React.useState(0);
	const [scaleAction, setScaleAction] = React.useState(0);
	const initState = async () => {
		if (!refImage.current) {
			setTimeout(async () => {
				await initState();
			}, 100)
			return
		}
		const { width: imageWidth } = refImage.current.getBoundingClientRect();
		if (imageWidth <= 0) {
			setTimeout(async () => {
				await initState();
			}, 100)
			return
		}

		setScaleAction(imageWidth / 1520);
		setScaleSpan(1520 / imageWidth);
	};
	React.useEffect(() => {
		(async () => {
			await initState();
		})();
	}, [imagePath])
	React.useEffect(() => {
		setImagePath(URL.createObjectURL(item.blob));
	}, []);

	const [contrast, setContrast] = React.useState(0);
	const handleChangeContrast = (_contrast) => {
		let _value = Math.trunc(_contrast*100)/100;
		setContrast(_value);
	};

	const refZoom = React.createRef();
	const [zoom, setZoom] = React.useState(1);
	const [disabledZoom, setDisabledZoom] = React.useState(false);
	const handleStopZoom = (event) => {
		let _value = Math.trunc((event.state?.scale || 1)*10)/10;
		setZoom(_value)
	};
	const handleChangeZoom = (_zoom) => {
		let isIn = Boolean(_zoom > zoom);
		if (isIn) {
			refZoom.current.zoomIn(_zoom - zoom);
		} else {
			refZoom.current.zoomOut(_zoom + zoom);
		}
		setZoom(_zoom);
	};
	const handleEnabledZoom = () => {
		setDisabledZoom(false);
	};
	const handleDisabledZoom = () => {
		setDisabledZoom(true);
	};

	const [leftSize, setLeftSize] = React.useState({
		width: item?.rects?.[`${ item.rectBaseName }l`]?.width,
		height: item?.rects?.[`${ item.rectBaseName }l`]?.height,
		changed: item?.rects?.[`${ item.rectBaseName }l`]?.changed || false,
	});
	const [rightSize, setRightSize] = React.useState({
		width: item?.rects?.[`${ item.rectBaseName }r`]?.width,
		height: item?.rects?.[`${ item.rectBaseName }r`]?.height,
		changed: item?.rects?.[`${ item.rectBaseName }r`]?.changed || false,
	});
	const handleChangeSize = (direction, _size, _position) => {
		if (direction === 'left') {
			setLeftSize({ ..._size, changed: true });
			setLeftPosition({ ..._position, changed: true });
		}
		if (direction === 'right') {
			setRightSize({ ..._size, changed: true });
			setRightPosition({ ..._position, changed: true });
		}
	};

	const [leftPosition, setLeftPosition] = React.useState({
		x: item?.rects?.[`${ item.rectBaseName }l`]?.x,
		y: item?.rects?.[`${ item.rectBaseName }l`]?.y,
		changed: item?.rects?.[`${ item.rectBaseName }l`]?.changed || false,
	});
	const [rightPosition, setRightPosition] = React.useState({
		x: item?.rects?.[`${ item.rectBaseName }r`]?.x,
		y: item?.rects?.[`${ item.rectBaseName }r`]?.y,
		changed: item?.rects?.[`${ item.rectBaseName }r`]?.changed || false,
	});
	const handleChangePosition = (direction, _position) => {
		if (direction === 'left') {
			setLeftPosition({
				x: _position.x || leftPosition.x,
				y: _position.y || leftPosition.y,
				changed: true
			});
		}
		if (direction === 'right') {
			setRightPosition({
				x: _position.x || rightPosition.x,
				y: _position.y || rightPosition.y,
				changed: true
			});
		}
		handleEnabledZoom();
	};

	const handleSubmit = () => {
		let rects = {...item.rects};
		function getParseValue (_object) {
			_object.height = parseInt(String(_object.height || '').match(/\d+/));
			_object.width = parseInt(String(_object.width || '').match(/\d+/));
			return _object
		}
		rects[`${ item.rectBaseName }l`] = getParseValue({
			...rects[`${ item.rectBaseName }l`],
			...leftSize,
			...leftPosition,
		});
		rects[`${ item.rectBaseName }r`] = getParseValue({
			...rects[`${ item.rectBaseName }r`],
			...rightSize,
			...rightPosition,
		});
		onSubmit(item.direction, rects);
	}

	return (
		<Box className={classes.root}>
			<Box className={classes.content}>
				<Box className={classes.box}>
					<TransformWrapper
						ref={refZoom}
						disabled={disabledZoom}
						disablePadding={true}
						panning={{
							excluded: ['panningDisabled'],
							velocityDisabled: false
						}}
						centerZoomedOut={true}
						onZoomStop={handleStopZoom}
					>
						<TransformComponent>
							<img
								ref={refImage}
								src={imagePath}
								className={classes.boxImage}
								style={{
									filter: `contrast(${1 + (contrast / 10)})`
								}}
							/>
							<Box
								className={classes.boxAction}
								sx={{transform: `scale(${scaleAction}) translate(-50%, -50%)`}}
							>
								<Rnd
									bounds="parent"
									scale={scaleAction * zoom}
									className={clsx({
										[classes.boxActionRnd]: true,
										'rnd-triangles': true,
										'--changed': Boolean(leftPosition.changed || leftSize.changed)
									})}
									position={{x: leftPosition?.x, y: leftPosition?.y}}
									size={{width: leftSize?.width, height: leftSize?.height}}
									onDragStart={handleDisabledZoom}
									lockAspectRatio={1}
									onDragStop={(e, d) => handleChangePosition('left', {x: d.x, y: d.y}, false)}
									onResizeStop={(e, direction, ref, delta, position) => handleChangeSize('left', {
										width: ref?.style?.width,
										height: ref?.style?.height
									}, position)}
								><span style={{transform: `scale(${scaleSpan})`}}>{allTranslations('Левый')}</span></Rnd>
								<Rnd
									bounds="parent"
									scale={scaleAction * zoom}
									className={clsx({
										[classes.boxActionRnd]: true,
										'rnd-triangles': true,
										'--changed': Boolean(rightPosition.changed || rightSize.changed)
									})}
									position={{x: rightPosition?.x, y: rightPosition?.y}}
									size={{width: rightSize?.width, height: rightSize?.height}}
									onDragStart={handleDisabledZoom}
									lockAspectRatio={1}
									onDragStop={(e, d) => handleChangePosition('right', {x: d.x, y: d.y}, false)}
									onResizeStop={(e, direction, ref, delta, position) => handleChangeSize('right', {
										width: ref?.style?.width,
										height: ref?.style?.height
									}, position)}
								><span style={{transform: `scale(${scaleSpan})`}}>{allTranslations('Правый')}</span></Rnd>
							</Box>
						</TransformComponent>
					</TransformWrapper>
					<div className={classes.caption}>{item?.caption}</div>
				</Box>
			</Box>
			<Box className={classes.information}>
				<Typography className={classes.title}>
					{allTranslations('Уточните область окуляров на')} <span>{allTranslations('фото')} {Boolean(item.rectBaseName === 'rect_0') ? 1 : 2}</span>
				</Typography>
				<Typography className={classes.message} dangerouslySetInnerHTML={{__html: allTranslations('На изображении укажите прямоугольные области Растягивая за границы и углы прямоугольников Для сохранения и перехода к следующему этапу разметки нажмите вверху на “Сохранить”')}}/>
				<Box mt={5}/>
				<ContrastSlider
					value={contrast}
					min={-10}
					max={10}
					label={allTranslations('Контрастность')}
					onChange={handleChangeContrast}
				/>
				<Box mt={5}/>
				<ContrastSlider
					value={zoom}
					min={1}
					max={8}
					step={1}
					label={allTranslations('Приближение')}
					onChange={handleChangeZoom}
				/>
				<Box mt="auto"/>
				<Box sx={{opacity: 0, pointerEvents: "none"}}>
					<Button
						id="OutlineGlassesSubmit"
						size="small"
						variant="contained"
						fullWidth={true}
						onClick={handleSubmit}
					>{allTranslations('Сохранить изменения')}</Button>
				</Box>
				<ResetChanged
					onClick={onClose}
				/>
			</Box>
		</Box>
	)
};

const useStylesElementRectsSmall = makeStyles(() => ({
	root: {
		display: "flex",
		width: "100%",
		flex: 1,
		boxShadow: "0 0 0 2px #E6E7EA",
		border: "8px solid white",
		position: "relative",
		overflow: "hidden",
		borderRadius: 24,
		cursor: "pointer",

		"&:after": {
			content: "''",
			float: "left",
			paddingTop: "100%"
		},
		"&.--loading:before": {
			content: `"${allTranslations('Идет расчет параметров')}"`,
			position: "absolute",
			top: "0",
			left: "0",
			width: "100%",
			height: "100%",
			zIndex: "10",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			fontSize: "20px",
			fontWeight: "500",
			lineHeight: "1",
			letterSpacing: "-0.03em",
			textAlign: "center",
			color: "white",
		},
		"&.--loading img": {
			filter: "grayscale(1)"
		},
		"&:not(.--loading):hover": {
			borderColor: "#210323",
			boxShadow: "0 0 0 2px #A72681",
		},
	},
	image: {
		maxWidth: "100%",
		maxHeight: "100%",
		position: "absolute",
		top: 0, left: 0,
		borderRadius: 0,
		pointerEvents: "none",
	},
	action: {
		position: "absolute",
		top: 0, left: 0,
		transformOrigin: "0% 0%",
		width: 1520,
		height: 1520
	},
	actionRnd: {
		display: "flex!important",
		alignItems: "center",
		justifyContent: "center",
		border: "6px solid #FFE500",
		borderRadius: 20,
		userSelect: "none",
		pointerEvents: "none",

		"& span": {
			display: "flex",
			fontWeight: "500",
			fontSize: "16px",
			lineHeight: "110%",
			letterSpacing: "-0.03em",
			color: "#FFE500",
			textShadow: "0px 2px 4px rgba(0, 0, 0, 0.4)",
		},
		"&.--changed": {
			borderColor: "#61FF00",
			"& span": {
				color: "#61FF00",
			}
		}
	},
	caption: {
		position: "absolute",
		left: 16, bottom: 16,
		pointerEvents: "none",

		fontSize: 14,
		lineHeight: 1,
		color: "#A72681",
		fontWeight: "500",
		letterSpacing: "-0.03em",
		textShadow: "0px 0px 8px #ffffff"
	},
}));
const useStylesDialogEditContour = makeStyles(() => ({
	root: {
		display: "flex",
		// alignItems: "flex-start",
		justifyContent: "flex-end",
		gap: 32,
	},

	content: {
		display: "flex",
		boxSizing: "border-box",
		width: "100%",
		maxWidth: 755,
		maxHeight: "calc(100vh - 204px)"
	},
	information: {
		display: "flex",
		flexDirection: "column",
		flex: "auto",
		maxWidth: 264
	},

	box: {
		display: "flex",
		width: "100%",
		boxShadow: "0 0 0 2px #D53CA8",
		border: "8px solid black",
		position: "relative",
		overflow: "hidden",
		borderRadius: 24,
		"&:after": {
			content: "''",
			float: "left",
			paddingTop: "100%"
		},
		"&.--loading img": {
			filter: "grayscale(1)"
		},
		"& .react-transform-wrapper": {
			display: "flex",
			width: "100%",
			height: "100%"
		},
		"& .react-transform-component": {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			width: "100%",
			height: "100%"
		},
	},
	boxImage: {
		maxWidth: "100%",
		maxHeight: "100%",
		position: "absolute",
		top: "50%", left: "50%",
		transform: "translate(-50%, -50%)",
		borderRadius: 18,
		pointerEvents: "none",
	},
	boxAction: {
		position: "absolute",
		top: "50%", left: "50%",
		// transform: "translate(-50%, -50%)",
		transformOrigin: "0% 0%",
		width: 1520,
		height: 1520
	},
	boxActionRnd: {
		display: "flex!important",
		alignItems: "center",
		justifyContent: "center",
		border: "6px solid #FFE500",
		borderRadius: 20,

		"& span": {
			display: "flex",
			fontWeight: "500",
			fontSize: "16px",
			lineHeight: "110%",
			letterSpacing: "-0.03em",
			color: "#FFE500",
			textShadow: "0px 2px 4px rgba(0, 0, 0, 0.4)",
		},
		"&.--changed": {
			borderColor: "#61FF00",
			"& span": {
				color: "#61FF00",
			}
		}
	},

	title: {
		fontWeight: "500",
		fontSize: "24px",
		lineHeight: "110%",
		letterSpacing: "-0.03em",
		color: "#210323",

		"& span": {
			color: palette.primary.main
		}
	},
	message: {
		marginTop: 12,

		fontWeight: "500",
		fontSize: "15px",
		lineHeight: "130%",
		letterSpacing: "-0.03em",
		color: "#828698",
	},
	caption: {
		position: "absolute",
		left: 16, bottom: 16,
		pointerEvents: "none",

		fontSize: 14,
		lineHeight: 1,
		color: "#A72681",
		fontWeight: "500",
		letterSpacing: "-0.03em",
		textShadow: "0px 0px 8px #ffffff"
	},
}));


const styles = {
	root: {
		display: "flex",
		gap: 32
	},
	calcContent: {
		display: "flex",
		gap: 16, flex: 1,
	},
	infoContent: {
		width: "100%",
		maxWidth: 280,
	},
	title: {
		fontWeight: "500",
		fontSize: "24px",
		lineHeight: "110%",
		letterSpacing: "-0.03em",
		color: "#210323",

		"& span": {
			color: palette.primary.main
		}
	},
	message: {
		marginTop: 12,

		fontWeight: "500",
		fontSize: "15px",
		lineHeight: "130%",
		letterSpacing: "-0.03em",
		color: "#828698",
	},
};
OutlineGlasses = withStyles(styles)(OutlineGlasses);

export default OutlineGlasses
