import React, {Component} from "react";
import {
	Box,
	Grid,
	Alert,
	Button,
	Skeleton,
	Container,
	Typography,
	Backdrop,
	CircularProgress
} from "@mui/material";
import {
	Notification,
	notificationTypes
} from "../../common/Notification";
import {
	ClientInfo as ClientInfoComponent,
	ResultInfo as ResultInfoComponent,
	PatientInfo as PatientInfoComponent,
	BottomControls as BottomControlsComponent,
	ListMeasurements as ListMeasurementsComponent
} from "./components";
import {
	palette
} from "../../theme/common";
import {
	HeaderOrder,
	VisibleContent
} from "../../components";
import agent from "../../agent/agent";
import moment from "moment";
import allTranslations from "../../locales/allTranslations";
import {idFormat} from "../../helpers/formaters";
import ReactToPrint from 'react-to-print';

class OrderView extends Component {
	constructor(props) {
		super(props);

		this.state = {
			order: {},
			paramsOfEyeglassFrame: {},
			orderId: props?.match?.params?.id,
			calculationId: null,
			isBackdrop: false,

			isLoadOrder: true,
			isHideResult: false,
			isLoadingResult: true,
		};
		// this.refContent = React.createRef();
		this.refPrint = React.createRef();
	}

	componentDidMount = async () => {
		await this.getOrder();
	}

	getOrder = async () => {
		const order = await agent.get(`/orders/${this.state.orderId}`).then((res) => {
			return res.data
		}).catch(() => {
			return null
		})
		if (!order) {
			Notification({
				message: allTranslations('Заказ не найден'),
				type: notificationTypes.error
			})
			return
		}

		await this.setState({
			order,
			isLoadOrder: false,
			calculationId: order?.calculations?.[0]?.id || null
		});

		if ((this.props?.location?.search || '').includes('open-order-edit')) {
			await this.editOrder(order);
		}

		const calculationId = order?.calculations?.[0]?.id;
		if (!calculationId) {
			Notification({
				message: allTranslations('По данному заказу не было измерений'),
				type: notificationTypes.warning
			});
			this.setState({
				isHideResult: true,
				isLoadingResult: false,
			})
			return
		}
		const paramsOfEyeglassFrame = await agent.patch(`/calculation/${order?.calculations?.[0]?.id}/get-params-of-eyeglass-frame`).then((res) => {
			return res.data?.paramsOfEyeglassFrame || null
		}).catch(() => {
			return null
		})
		await this.setState({
			paramsOfEyeglassFrame,
			isLoadingResult: false,
			isHideResult: Boolean(!paramsOfEyeglassFrame),
		})
	}
	editOrder = async (localOrder) => {
		const {
			order,
			orderId
		} = this.state;

		// Логика получения информации про поциента
		const patientId = order?.patientId || localOrder?.patientId;
		const patient = await agent.get(`/patients/${patientId}`).then((res) => {
			return res.data?.patient || null
		}).catch(() => {
			return null
		});
		this.props.setPatient(patient);
		localStorage.setItem('patient_id', patientId);

		this.props.history.push(`/orders/created?order=${orderId}`);
	}

	printOrder = async () => {


		// const divToPrint = document.getElementById('page-print');
		// const newWin = window.open("");
		// newWin.document.write(divToPrint.outerHTML);
		// newWin.print();
		// newWin.close();
	}
	eventClickPrint = () => {
		this.refPrint.current.handleClick()
	}

	sendTo1c = async () => {
		this.setState({isBackdrop: true});
		const res = await agent.post(`/calculation/${this.state.calculationId}/send-to-1c`, {
			calculationId: this.state.calculationId
		}).then((res) => {
			return res.data
		}).catch((err) => {
			return {error: true}
		});
		if (res.error || !res) {
			this.setState({isBackdrop: false});
			Notification({
				message: allTranslations('Ошибка отправки заказа в 1C'),
				type: notificationTypes.error
			})
			return
		}
		this.setState({isBackdrop: false});
		Notification({
			message: allTranslations('Заказ успешно отправлен в 1C'),
			type: notificationTypes.success
		})
	}


	_headerLabel = () => {
		const { order } = this.state;
		if (!order.createdAt) {
			return '...'
		}
		return `№${idFormat(order.id, '#####')}`
	}
	_headerCaption = () => {
		const { order } = this.state;
		if (!order.createdAt) {
			return ''
		}
		return `${allTranslations('Дата заполнения')}:<br/><span style={{color: "#000000", fontWeight: "500"}}>${moment(order.createdAt).format("DD.MM.YYYY")}</span>`
	}

	render() {
		const {
			order,
			isBackdrop,
			calculationId,
			paramsOfEyeglassFrame,

			isLoadOrder,
			isHideResult,
			isLoadingResult,
		} = this.state;

    return (
      <>
	      <HeaderOrder
		      label={this._headerLabel()}
		      caption={this._headerCaption()}
		      onClickRight={this.editOrder}
	      />
        <Container id="page-print" maxWidth="xl">
	        <Box ref={(el) => (this.refContent = el)}>
		        <VisibleContent visible={Boolean(!isLoadOrder)}>
			        <ClientInfoComponent patient={order?.patient}/>
		        </VisibleContent>
		        <VisibleContent visible={Boolean(isLoadOrder)}>
			        <Skeleton sx={{ height: 240, transform: 'initial' }}/>
		        </VisibleContent>
		        <Box mt={4}/>
		        <ResultInfoComponent
			        params={paramsOfEyeglassFrame}
			        isError={isHideResult}
			        isLoading={isLoadingResult}
		        />
	        </Box>

	        <Box mt={3}/>
	        <BottomControlsComponent
		        isPrint={!Boolean(isLoadingResult || isHideResult)}
		        onPrint={this.eventClickPrint}

		        isOneS={Boolean(!!calculationId && order?.externalId)}
		        onSend1C={this.sendTo1c}
	        />

	        <ReactToPrint ref={this.refPrint} content={() => this.refContent}/>
        </Container>

	      <Backdrop open={isBackdrop}>
		      <CircularProgress/>
	      </Backdrop>
      </>
    );
	}
}

export default OrderView
