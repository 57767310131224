// @flow
import { compose } from 'recompose';
import { connect } from 'react-redux';

import Settings from './Settings';
import {setAuthMe} from "../../states/global";

export default compose(
  connect(
    state => ({
      user: state.global.authMe,
      settings: state.global.settings
    }),
    dispatch => ({
      setAuthMe: (user) => dispatch(setAuthMe(user)),
    }),
  ),
)(Settings);
